@font-face {
    font-family: 'nazanin';
    src: url('../fonts/Nazanin-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'nazanin';
    src: url('../fonts/Nazanin-Bold.ttf') format('truetype');
    font-weight: bold;
}
body { padding-top: 70px; }
*, .page-title{
    font-family: nazanin;
    
}
table td{
    font-size: 1.03em;
}
.dropdown-menu button.btn-link{    
    width: 100% !important;
    text-align: right !important;
    padding-right: 16px !important;
}
.dropdown-menu button.btn-link:hover{
    text-decoration: none !important;
    color: #2b3c4e !important;
    background-color: #e1e5ec !important;
}
.rotate {

/* Safari */
-webkit-transform: rotate(-90deg);

/* Firefox */
-moz-transform: rotate(-90deg);

/* IE */
-ms-transform: rotate(-90deg);

/* Opera */
-o-transform: rotate(-90deg);

/* Internet Explorer */
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}
.form-control{
    font-size: 16px !important;
}
.table-responsive{
    clear:both;
}
.datatable-footer-input{
    display:table-cell; width:100%;
    padding-left: 5px;
    padding-right: 5px;
}
.btn {
    margin-right: 2px;
    margin-left: 2px;
}
.clear-both {
	clear:both;
}
.align-right {
	text-align:right;
}
.ltr {
	direction:ltr;
	text-align:left;
    unicode-bidi: embed;
}
.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
     border-bottom: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s ease infinite;

    position: relative;
    margin-top: -27px;
    right: 30px;
    float: right;
    display:none;
}

html[dir] .loader {
    right: initial !important;
    left: 30px ;
    float: left !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.table .btn{
    margin-bottom: 4px
}
table .select2-container {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
}
table .select2-container .select2-choice, .panel-heading .select2-container .select2-choice{
    height: 24px;
    line-height: 18px;
}
table .panel-heading .select2-container .select2-choice abbr {
    top: 5px;
}
table .select2-container .select2-choice .select2-arrow b {
    background-position: 1px -1px !important;
}
table .select2-container .select2-choice abbr {
    top:5px;
}